<template>
  <el-container>
    <!-- Header -->
    <el-header class="Header">
      <el-row class="centered-row">
        <p id="receiver"> {{ receiver }}&nbsp;</p>
        <el-button class="spaced-elements" @click="infoDrawer = true" icon="el-icon-arrow-down" circle></el-button>
        <el-button class="spaced-elements" @click="showUserList" icon="el-icon-user" circle></el-button>
        <el-button class="spaced-elements" @click="clearMsg" icon="el-icon-delete" circle></el-button>
      </el-row>
      <!-- 顶部抽屉 -->
      <el-drawer :show-close="false" style="color: grey;" title="" :visible.sync="infoDrawer" :direction="'ttb'"
        :before-close="handleClose">
        {{ userName }} <br> {{ "在线用户: " + onlineClientNum }} <br> {{ "历史用户:" + historyClientNum }}
      </el-drawer>
    </el-header>


    <el-container>
      <!-- Aside -->
      <!-- 用户列表抽屉 -->
      <el-drawer :withHeader="false" id="drawerAside" title="" :visible.sync="userListDrawer" :direction="'ltr'"
        :before-close="clearUserList">
        <ul id="userList"> </ul>
      </el-drawer>

      <el-container>
        <!-- chatBox -->
        <el-main ref="chatBox" id="chatBox">
        </el-main>

        <!-- Footer -->
        <el-footer class="Footer">
          <!-- toolkit -->
          <el-row class="tools">
            <el-button @click="stream" icon="el-icon-video-camera" square></el-button>
            <el-button @click="setUserNameBool = true" icon="el-icon-edit" square></el-button>
            <el-button @click="sendBtn" icon="el-icon-check" square></el-button>
            <el-button @click="uploadImg" icon="el-icon-picture-outline" square></el-button>
            <input id="uploadImg" ref="uploadImg" type="file" @change="handleFileUpload" accept="image/*">
          </el-row>


          <!-- 视频 -->
          <el-drawer v-if="true" class="videoBox" ref="dv" :withHeader="false" :visible.sync="drawervideo"
            :direction="'btt'" :size="'90%'" :before-close="stopCamera">

            <button class="videoBtn" type="button" id="signalBtn" onclick="initVar()">
              点击连接到信令服务器
            </button>

            <button class="videoBtn" type="button" id="pushBtn" onclick="start()">
              推流
            </button>

            <video id="local-video"></video>

            <div id="remotes">

              <button class="videoBtn" type="button" id="enable-audio-button" onclick="enableAudio()">
                启用音频
              </button>
            </div>
          </el-drawer>


          <!-- 登录 -->
          <el-dialog id="login" ref="setUserNamedialog" title="登录/注册" :visible.sync="setUserNameBool"
            :before-close="checkLogin">
            <el-form>
              <el-form-item label="">
                <el-input class="inputUserName" @keydown.native="handleConfirm($event)" v-model="userName"
                  autocomplete="off" placeholder="用户名">
                </el-input>
                <el-input @keydown.native="handleConfirm($event)" v-model="pwd" autocomplete="off" placeholder="密码">
                </el-input>
              </el-form-item>
            </el-form>
            <div ref="logInfo"></div>
            <div slot="footer" class="dialog-footer">
              <el-button style="width: 100%;" type="primary" @click="loginBtn">登录/注册</el-button>
            </div>
          </el-dialog>


          <!-- 放大图片 -->
          <el-dialog title="" class="zoomDialog" :visible.sync="zoomImgFlag" :show-close="false" :width="'50%'"
            :withHeader="false">
            <img ref="zoomImg" src="" alt="">
          </el-dialog>


          <!-- 输入区 -->
          <el-input @keydown.native="handleSend($event)" type="textarea" :rows="13" placeholder="请输入内容"
            v-model="textarea">
          </el-input>

        </el-footer>
      </el-container>
    </el-container>
  </el-container>
</template>

<script>
import CryptoJS from 'crypto-js';
export default {
  data() {
    return {
      img: "",
      receiver: "",
      pwd: "",

      //sendMsg
      sendMsg: {

      },
      userList: {

      },

      //
      textarea: '',
      userName: '',

      //Dialog
      zoomImgFlag: false,

      //抽屉组件
      infoDrawer: false,
      userListDrawer: false,
      drawervideo: false,

      //v-show
      setUserNameBool: false,

      //info
      onlineClientNum: '',
      historyClientNum: '',

    }
  },

  // <!-- ============================================================================================================ -->
  methods: {
    showUserList() {
      this.userListDrawer = true
      setTimeout(() => {
        this.appendHome();
        for (const i in this.userList) {
          this.appendUser(this.userList[i]);
        }
      }, 1);
    },
    appendHome() {
      var box = document.getElementById("userList")
      var home = document.createElement("li")
      home.classList.add("userListItem")
      home.classList.add("el-icon-s-home")
      home.addEventListener('click', () => {
        this.receiver = ""
        this.clearUserList()
        this.userListDrawer = false
      })
      box.appendChild(home)

    },
    appendUser(name) {
      var box = document.getElementById("userList")
      var user = document.createElement("li")
      user.classList.add("userListItem")
      user.textContent = name
      user.addEventListener('click', () => {
        this.receiver = user.textContent
        this.clearUserList()
        this.userListDrawer = false
      })
      box.appendChild(user)

    },
    uploadImg() {
      this.$refs.uploadImg.click()
    },
    async handleFileUpload(event) {
      const input = event.target;
      if (input.files && input.files[0]) {
        const file = input.files[0];
        // 检查文件类型是否为图像
        if (file.type.startsWith('image/')) {
          const reader = new FileReader();
          // 读取文件内容
          reader.readAsDataURL(file);

          reader.onload = async () => {
            // 创建一个图片元素
            const image = new Image();

            image.onload = async () => {
              // 压缩图片
              const compressedBase64 = await this.compressImage(image, 20480);
              // this.sendToServer('message', 'img', reader.result)
              this.sendToServer('message', 'img', compressedBase64)
            };

            image.src = reader.result;
          };


        } else {
          alert('请选择一个图像文件');
        }
      }
      this.$refs.uploadImg.value = ""
    },
    async compressImage(image, maxSizeInBytes) {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');

      // 设置画布大小
      canvas.width = image.width; // 设置压缩后的宽度
      canvas.height = image.height; // 设置压缩后的高度

      let quality = 0.9; // 初始的图像质量
      for (let i = 0; i < 100; i++) {

        // 在画布上绘制图像并进行压缩
        ctx.drawImage(image, 0, 0, canvas.width, canvas.height);

        // 将画布内容转为Base64编码
        const compressedBase64 = canvas.toDataURL('image/jpeg', quality);

        // 将Base64编码转为Blob对象
        const blob = await fetch(compressedBase64).then((res) => res.blob());

        // 检查Blob的大小是否小于目标大小
        if (blob.size <= maxSizeInBytes || i == 99) {
          return compressedBase64;
        }

        // 如果图像大小仍然过大，降低质量
        quality -= 0.01;
        console.log("压缩", i+1, "次")

      }
      
    },

    stream() {
      this.drawervideo = true
    },
    clearMsg() {
      var chatBox = document.getElementById("chatBox")
      while (chatBox.firstChild) {
        chatBox.removeChild(chatBox.firstChild)
      }
    },
    clearUserList() {
      var userList = document.getElementById("userList")
      while (userList.firstChild) {
        userList.removeChild(userList.firstChild)
      }
      this.userListDrawer = false
    },
    initSendMsg(event, type, msg, oldname) {
      this.sendMsg.event = event
      this.sendMsg.receiver = this.receiver
      this.sendMsg.type = type
      this.sendMsg.oldname = oldname
      this.sendMsg.time = this.getCurrentTime()
      this.sendMsg.name = this.userName
      this.sendMsg.msg = msg
      return JSON.stringify(this.sendMsg)
    },

    sendBtn() {
      if (this.textarea == "") {
        return
      }
      this.sendToServer('message', 'text', this.textarea)
    },

    loginBtn() {

      if (this.userName == null) {
        this.$refs.logInfo.textContent = "用户名不能为空！"
        return
      }

      if (this.pwd == "") {
        this.$refs.logInfo.textContent = "密码不能为空！"
        return
      }

      this.login()
      return

    },

    login() {
      var hashPwd = CryptoJS.SHA3(this.pwd).toString()
      var loginMsg = {
        event: "login",
        name: this.userName,
        pwd: hashPwd,
      }
      var loginJSON = JSON.stringify(loginMsg)
      ws.send(loginJSON)

    },


    sendToServer(event, msgType, msg) {
      this.supershowMsg(0, msgType, msg)
      ws.send(this.initSendMsg(event, msgType, msg))
    },

    checkLogin(done) {
      // this.setUserNameBool = true
      done()
    },


    supershowMsg(sender, type, msg, senderName, color) {//sender=0是自己发送的消息sender=1是其他用户发送的消息sender=2是系统消息
      var chatBox = document.getElementById("chatBox");
      var sendContent
      switch (sender) {
        case 0:
          if (type == 'text') {
            sendContent = document.createElement('div');
            sendContent.classList.add('sendMsgText');
            sendContent.textContent = msg;
            this.textarea = ""
          }

          if (type == 'img') {
            sendContent = document.createElement('img');
            if (chatBox.clientWidth > chatBox.clientHeight) {
              sendContent.classList.add('sendYourImg');
            } else {
              sendContent.classList.add('sendYourImg2');
            }
            sendContent.src = msg;
            sendContent.addEventListener('click', () => {
              this.zoomImg(msg)
            })

          }

          break;
        case 1:
          if (type == 'text') {
            sendContent = document.createElement('div');
            sendContent.classList.add('reciveMsgText');
            sendContent.textContent = senderName + ":" + msg;
          }
          if (type == 'img') {
            this.supershowMsg(1, 'text', '', "[" + senderName + "图片]")
            sendContent = document.createElement('img');
            sendContent.classList.add('sendImg');
            sendContent.src = msg;
            sendContent.addEventListener('click', () => {
              this.zoomImg(msg)
            })
          }
          break;
        case 2:
          sendContent = document.createElement('div');
          sendContent.classList.add('reciveMsgText');
          if (color == 'green') {
            sendContent.classList.add('green');
            sendContent.textContent = "已连接";
          }
          if (color == 'red') {
            sendContent.classList.add('red');
            sendContent.textContent = "连接已断开";
          }
          break;

        default:
          break;
      }

      chatBox.appendChild(sendContent)
      chatBox.scrollTop = chatBox.scrollHeight
    },

    // Listening key Enter
    handleSend(event) {
      if (event.keyCode == 13) {
        event.preventDefault();
        if (this.textarea == "") {
          return
        }
        this.sendToServer('message', 'text', this.textarea)
      }

    },

    handleConfirm(event) {
      if (event.keyCode == 13) {
        this.loginBtn()
      }
    },

    randInt(min, max, spliter) {
      return spliter + (Math.floor(Math.random() * max - min) + min);
    },

    handleClose(done) {
      done();
    },


    open() {
      this.$message('');
    },

    getCurrentTime() {
      const now = new Date();
      const hours = now.getHours();
      const minutes = now.getMinutes();
      // 格式化小时和分钟，确保它们都是两位数
      const formattedHours = hours < 10 ? `0${hours}` : hours;
      const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
      // 构建时间字符串（hh:mm 格式）
      const currentTime = `${formattedHours}:${formattedMinutes}`;
      return currentTime;
    },

    stopCamera(done) {
      done()
    },

    connectWs() {
      // 连接成功时触发
      ws.onopen = () => {
        //通知连接成功
        if (this.userName != "" || this.userName != null) {
          ws.send(this.initSendMsg("userNum"))
        }
        this.supershowMsg(2, '', '', '', 'green')
      }
      // 接收到消息时触发  
      ws.onmessage = (e) => {
        //将服务器广播的JSON格式化成data对象
        const data = JSON.parse(e.data)
        console.log(data)

        switch (data.event) {
          case 'message':
            if (this.userName != data.userName) {
              this.supershowMsg(1, data.type, data.message, data.userName)
            }
            break;

          case 'hismessage':
            if (data.message != undefined) {
              if (this.userName != data.userName) {
                //如果是别人发送的消息
                this.supershowMsg(1, data.type, data.message, data.userName)
              } else {
                //如果是自己发送的消息
                this.supershowMsg(0, data.type, data.message)
              }
            }
            break;


          //如果消息类型为用户加入
          case 'connected':
            this.onlineClientNum = data.onlineClientNum
            this.historyClientNum = data.historyClientNum
            break;

          //更新用户列表
          case 'updateUserList':
            this.userList = data.userList
            break;

          case 'login':
            //result:bool info:string
            if (data.result) {
              this.setUserNameBool = false
              localStorage.setItem("userName", this.userName)
            } else {
              this.$refs.logInfo.textContent = data.info
            }

            break;

          default:
            break;
        }

      };
      // 连接关闭时触发  
      ws.onclose = () => {
        this.supershowMsg(2, '', '', '', 'red')
      };
    },

    zoomImg(src) {

      // 创建一个蒙版元素
      var overlay = document.createElement("div");
      overlay.style.position = "fixed";
      overlay.style.top = "0";
      overlay.style.left = "0";
      overlay.style.width = "100%";
      overlay.style.height = "100%";
      overlay.style.backgroundColor = "rgba(0, 0, 0, 0)";
      overlay.style.display = "flex";
      overlay.style.alignItems = "center";
      overlay.style.justifyContent = "center";
      overlay.style.zIndex = "1000";
      overlay.style.transition = "background-color 0.3s ease-in-out";

      // 创建一个图片元素
      var img = document.createElement("img");
      img.src = src;
      img.style.animation = "fadeIn 0.5s ease"; // 添加渐变动画
      if (img.naturalWidth > img.naturalHeight) {
        console.log("111")
        img.style.width = "80vw";
      } else {
        console.log("222")
        img.style.height = "80vh";
      }

      // 添加图片到蒙版中
      overlay.appendChild(img);

      // 点击蒙版时隐藏图片和蒙版
      overlay.addEventListener("click", function () {
        overlay.style.backgroundColor = "rgba(0, 0, 0, 0)";
        img.style.opacity = 0; // 隐藏图片
        setTimeout(function () {
          overlay.style.display = "none";
        }, 300); // 等待过渡效果完成后隐藏蒙版
      });

      // 将蒙版添加到页面的body中
      document.body.appendChild(overlay);

      // 触发过渡效果
      setTimeout(function () {
        overlay.style.backgroundColor = "rgba(0, 0, 0, 0.5)";
        img.style.opacity = 1; // 显示图片
      }, 0);
    },





  },





  // ============================ mounted ========================================




  mounted() {
    //设置标签页名称
    document.title = 'Gochat';

    //获取保存在浏览器的用户名
    this.userName = localStorage.getItem("userName")

    //如果用户名为空打开输入用户名的界面
    if (this.userName == null) {
      this.setUserNameBool = true
    }
    this.connectWs()
  }
}
const ws = new WebSocket("wss://chat.faixin.cn:8888/ws");
// const ws = new WebSocket("ws://localhost:8888/ws");

</script>